/* eslint-disable no-console */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { updateBonusRounds } from "./actions";
import Select from "react-select";
import {
  permissionsConstants,
  PermissionsFragment,
  checkPermissions,
} from "../app/permissions-fragment";
import { format } from "../shared/utils/dates";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { useParams } from "react-router-dom";
import Pagination from "shared/pagination.js";
import styles from "./bonus-rounds.module.scss";
import bettingApi from "app/http-betting-api";

const UserBonusRounds = ({ bonus_rounds, updateBonusRounds }) => {
  let { id } = useParams();
  const [bonusRoundTypeFilter, setBonusRoundTypeFilter] = useState("all");
  const [gameType, setGameType] = useState("");
  const [bonusRoundV2, setBonusRoundV2] = useState([]);
  const [page, setPage] = useState(1);
  const [amountOfRounds, setAmountOfRounds] = useState(0);
  const [rounds, setRounds] = useState("");
  const [gameTypeV2, setGameTypeV2] = useState("");
  const [transactions, setTransactions] = useState({
    pagination: {
      total_pages: 1,
      records_per_page: 50,
    },
    records: [],
  });

  const updateLedger = () => {
    const params = {
      page: page,
      limit: 25,
      bonus_round_type_filter: bonusRoundTypeFilter,
    };

    window.api
      .get(`/admin/users/${id}`, {
        params: params,
      })
      .then((data) => setTransactions(transactions));
  };

  const getUserBonusRounds = () => {
    const url = `/admin/free-bets/${id}`;
    bettingApi
      .get(url)
      .then((data) => setBonusRoundV2(data || []))
      .catch((err) => {
        console.log(err);
      });
  };

  const addNewBonusRounds = () => {
    if (isNaN(+rounds) || !gameTypeV2) {
      return;
    }
    const payload = { user_id: id, rounds, type: gameTypeV2, note: "admin" };
    bettingApi.post("/admin/free-bets/credit", payload).then(() => {
      getUserBonusRounds();
      setRounds("");
    });
  };

  useEffect(() => {
    if (checkPermissions(permissionsConstants.USER_BONUS_ROUNDS_VIEW_LIST)) {
      updateBonusRounds(id);
    }

    if (
      checkPermissions(
        permissionsConstants.USER_BONUS_ROUNDS_TRANSACTIONS_VIEW_LIST
      )
    ) {
      updateLedger();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, updateBonusRounds, bonusRoundTypeFilter]);

  useEffect(() => {
    if (!checkPermissions(permissionsConstants.USER_BONUS_ROUNDS_VIEW_LIST)) {
      return;
    }
    getUserBonusRounds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <h4>Bonus Rounds - Monolith</h4>
      <PermissionsFragment
        feature={permissionsConstants.USER_BONUS_ROUNDS_VIEW_LIST}
      >
        <Table>
          <Thead>
            <Tr>
              <Th>Type</Th>
              <Th>Rounds</Th>
              <Th>Balance</Th>
            </Tr>
          </Thead>

          <Tbody>
            {bonus_rounds.map((bonus) => {
              return (
                <Tr>
                  <Td>{bonus.type}</Td>
                  <Td>{bonus.rounds}</Td>
                  <Td>{bonus.balance}</Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </PermissionsFragment>

      <PermissionsFragment feature={permissionsConstants.CREDIT_BONUS_ROUNDS}>
        <div className={styles.form}>
          <h4>Modify spins</h4>
          <div className={styles.editRoles}>
            <Select
              placeholder={"Select a bonus round type"}
              name="type"
              value={gameType}
              onChange={(option) => setGameType(option.value)}
              options={[
                { value: "crash", label: "Crash" },
                { value: "roulette", label: "Double" },
                { value: "mines", label: "Mines" },
                { value: "plinko", label: "Plinko" },
                { value: "limbo", label: "Limbo" },
                { value: "dice", label: "Dice" },
              ]}
            />

            <input
              placeholder="ex. 4"
              onChange={(e) => setAmountOfRounds(e.target.value)}
            />

            <button
              onClick={() => {
                let amount = amountOfRounds;

                if (isNaN(+amount)) {
                  return;
                }
                if (!gameType) {
                  return;
                }
                window.api
                  .post("/admin/credit_bonus_rounds", {
                    user_id: id,
                    rounds: amount,
                    type: gameType,
                    note: "admin",
                  })
                  .then((data) => {
                    updateBonusRounds(id, 1);
                    updateLedger();
                  });
              }}
              disabled={isNaN(+amountOfRounds) || !gameType}
            >
              Add Rounds
            </button>
          </div>
        </div>
      </PermissionsFragment>

      <h4>Bonus Rounds - Gaming Service</h4>
      <PermissionsFragment
        feature={permissionsConstants.USER_BONUS_ROUNDS_VIEW_LIST}
      >
        <Table>
          <Thead>
            <Tr>
              <Th>Type</Th>
              <Th>Rounds</Th>
              <Th>Balance</Th>
            </Tr>
          </Thead>

          <Tbody>
            {bonusRoundV2.map((bonus) => {
              return (
                <Tr>
                  <Td>{bonus.type}</Td>
                  <Td>{bonus.rounds}</Td>
                  <Td>{bonus.balance}</Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </PermissionsFragment>

      <PermissionsFragment feature={permissionsConstants.CREDIT_BONUS_ROUNDS}>
        <div className={styles.form}>
          <h4>Modify Bonus Rounds</h4>
          <div className={styles.editRoles}>
            <Select
              placeholder={"Select a bonus round type"}
              name="type"
              value={gameTypeV2}
              onChange={(option) => setGameTypeV2(option.value)}
              options={[
                { value: "coin-flip", label: "Coin Flip" },
                { value: "neymar-crash", label: "Crash Neymar" },
                { value: "crash-v2", label: "Crash II" },
                { value: "crash", label: "Crash" },
                { value: "fruits", label: "Fruits" },
                { value: "hilo", label: "Hilo" },
                { value: "slide", label: "Slide" },
                { value: "tower", label: "Tower" },
                { value: "fruitSlice", label: "Fruit Slice" },
                { value: "fortune_double", label: "Fortune Double" },
                { value: "mines", label: "Mines" },
                { value: "roulette", label: "Double" },
                { value: "limbo", label: "Limbo" },
              ]}
            />

            <input
              placeholder="ex. 4"
              value={rounds}
              onChange={(e) => setRounds(e.target.value || "")}
            />

            <button
              disabled={isNaN(+rounds) || +rounds <= 0 || !gameTypeV2}
              onClick={addNewBonusRounds}
            >
              Add Rounds
            </button>
          </div>
        </div>
      </PermissionsFragment>

      <PermissionsFragment
        feature={permissionsConstants.USER_BONUS_ROUNDS_TRANSACTIONS_VIEW_LIST}
      >
        <div className={styles.form}>
          <h4>Transaction Ledger</h4>
          <div className={styles.editRoles}>
            <Select
              placeholder={"Filter for bonus round type"}
              name="type"
              value={bonusRoundTypeFilter}
              onChange={(option) => {
                setBonusRoundTypeFilter(option && option.value);
                updateLedger();
              }}
              options={bonus_rounds.map((round) => ({
                label: `ID: ${round.id} - ${round.type}`,
                value: round.type,
              }))}
            />
          </div>
        </div>

        <Table>
          <Thead>
            <Tr>
              <Th>ID</Th>
              <Th>Created</Th>
              <Th>Bonus round Type</Th>
              <Th>Amount</Th>
              <Th>Closing Balance</Th>
              <Th>Note</Th>
            </Tr>
          </Thead>

          <Tbody>
            {transactions.records.map((record) => {
              return (
                <Tr key={record["created_at"]}>
                  <Td>{record.id}</Td>
                  <Td>{format.withMinutes(record["created_at"])}</Td>
                  <Td>{record.type}</Td>
                  <Td>{record.amount}</Td>
                  <Td>{record.closing_balance}</Td>
                  <Td>{record.note}</Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        <Pagination
          total_pages={transactions.pagination["total_pages"]}
          update={(page) => {
            setPage(page);
            updateLedger();
          }}
        />
      </PermissionsFragment>
    </>
  );
};

const mapStateToProps = (state) => ({
  ...state.users.user,
});
const mapDispatchToProps = (dispatch) => ({
  updateBonusRounds: (user_id, page) =>
    dispatch(updateBonusRounds(user_id, page)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserBonusRounds);
